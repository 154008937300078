<template>
  <main class="edit-user admin-page bg-cream px-12 ml-auto">
    <div class="container min-h-screen flex flex-col pt-12 pb-20">
      <header class="admin-page-header mb-10">
        <h1 class="text-3xl text-blue">Administradores / Editar cadastro</h1>
      </header>

      <Loader :isloading="loading" />

      <nivea-form v-if="!loading" :submit="submit">
        <field name="name" label="Nome" rules="required" v-slot="{ label }">
          <v-text-field v-model="form.name" :label="label" hide-details="auto" />
        </field>

        <field name="email" label="E-mail" rules="required|email" v-slot="{ label }">
          <v-text-field type="email" v-model="form.email" autocomplete="username" :label="label" hide-details="auto" />
        </field>

        <field name="password" label="Nova Senha" v-slot="{ label }">
          <v-text-field vclass="pr-28" type="password" name="password" v-model="form.new_password" autocomplete="new-password" :label="label" hide-details="auto" />
          <span class="field-tag bg-cream absolute top-1 right-0 text-orange text-xs px-5 py-2 rounded-lg">Opcional</span>
        </field>

        <field name="confirm-new-password" label="Confirmar Nova Senha" rules="confirmed:password" vid="confirmation" data-vv-as="password" v-slot="{ label }">
          <v-text-field type="password" name="new-password" v-model="form.new_password_confirmation" autocomplete="new-password" :label="label" hide-details="auto" />
        </field>

        <Button class="mt-10" type="submit">Cadastrar</Button>
      </nivea-form>
    </div>
  </main>
</template>

<script>
import api from '@/utils/api.js';
import Loader from '@/components/Loader';
import NiveaForm from '@/components/NiveaForm';
import Field from "@/components/fields/Field";
import Button from "@/components/Button";

export default {
  name: "EditAdmin",
  components: {
    NiveaForm,
    Loader,
    Field,
    Button,
  },
  data() {
    return {
      loading: false,
      form: {
        name: '',
        email: '',
        new_password: '',
        new_password_confirmation: ''
      }
    }
  },
  mounted() {
    this.fetchAdmin();
  },
  methods: {
    async fetchAdmin() {
      this.loading = true;

      const response = await api.get(`admins/${this.$route.params.id}`);
      this.form = response.data;
      this.loading = false;
    },

    async submit() {
      await api.put(`admins/${this.$route.params.id}`, this.form);
      this.$noty.success('Administrador atualizado com sucesso.');
      this.$router.push({ name: 'Admins' });
    }
  }
};
</script>

<style lang="scss">
.edit-user {
  .nivea-form {
    width: 480px;
  }

  .field + .field {
    @apply mt-12;
  }

  .field-tag {
    border: 1px solid rgba(233, 119, 85, 0.3);
  }
}
</style>
