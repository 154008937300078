var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"edit-user admin-page bg-cream px-12 ml-auto"},[_c('div',{staticClass:"container min-h-screen flex flex-col pt-12 pb-20"},[_vm._m(0),_c('Loader',{attrs:{"isloading":_vm.loading}}),(!_vm.loading)?_c('nivea-form',{attrs:{"submit":_vm.submit}},[_c('field',{attrs:{"name":"name","label":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
return [_c('v-text-field',{attrs:{"label":label,"hide-details":"auto"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,false,3995761019)}),_c('field',{attrs:{"name":"email","label":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
return [_c('v-text-field',{attrs:{"type":"email","autocomplete":"username","label":label,"hide-details":"auto"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,false,4273176948)}),_c('field',{attrs:{"name":"password","label":"Nova Senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
return [_c('v-text-field',{attrs:{"vclass":"pr-28","type":"password","name":"password","autocomplete":"new-password","label":label,"hide-details":"auto"},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}}),_c('span',{staticClass:"field-tag bg-cream absolute top-1 right-0 text-orange text-xs px-5 py-2 rounded-lg"},[_vm._v("Opcional")])]}}],null,false,2171467970)}),_c('field',{attrs:{"name":"confirm-new-password","label":"Confirmar Nova Senha","rules":"confirmed:password","vid":"confirmation","data-vv-as":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
return [_c('v-text-field',{attrs:{"type":"password","name":"new-password","autocomplete":"new-password","label":label,"hide-details":"auto"},model:{value:(_vm.form.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "new_password_confirmation", $$v)},expression:"form.new_password_confirmation"}})]}}],null,false,2234465760)}),_c('Button',{staticClass:"mt-10",attrs:{"type":"submit"}},[_vm._v("Cadastrar")])],1):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"admin-page-header mb-10"},[_c('h1',{staticClass:"text-3xl text-blue"},[_vm._v("Administradores / Editar cadastro")])])}]

export { render, staticRenderFns }