<template>
  <div :class="{
    'flex justify-center': true,
    'hidden': !isloading
  }">
    <v-btn :loading="true"
      outlined
      large
      fab
      color="success"
      class="margin-auto border-0 blue bg-white loading"
    />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: ['isloading'],
}
</script>

<style lang="scss">
  .loading {
    circle {
      stroke: #00136F;
    }
  }
</style>
